import React from 'react'
import { Container } from 'react-bootstrap';

 const AddInsident = () => {
  return (
    <>
      <div className='add_insident_container'>
        <Container>
          <h>Add insident</h>
        </Container>
      </div>
    </>
  )
}
export default AddInsident;