import wailalogo from "../../Assets/Images/logo_bdo.png"
// import products from "../../Assets/Images/product's.png"
import introImage from "../../Assets/Images/intro.png"
import videoContent from "../../Assets/Videos/video.mp4"
// import FacebookIcon from "@mui/icons-material/Facebook"
// import YouTubeIcon from "@mui/icons-material/YouTube"
// import InstagramIcon from "@mui/icons-material/Instagram"
// import TwitterIcon from "@mui/icons-material/Twitter"
import fieldsaleLogo from "../../Assets/Images/fieldsale.svg"
import tracktaskLogo from "../../Assets/Images/tracktask.svg"
import tymeplusLogo from "../../Assets/Images/tymeplus.svg"
import crmLogo from "../../Assets/Images/crm.svg"
import tymeplusDashboard from "../../Assets/Images/tymeplus_dashboard.png"
import tractaskdark from "../../Assets/Images/tractaskdark.png"
import tractaskwhite from "../../Assets/Images/tractaskwhite.png"
import tractasklist from "../../Assets/Images/tractasklist.png"
import filedsaleDashboard from "../../Assets/Images/filedsaleDashboard.png"
import Calculator from "../../Components/Pages/WorkSpace/Component/Calculator"
import crmdashboard from "../../Assets/Images/CRM/crm_1.png"

export const WorkSpaceJson = {
  header: {
    Logo: wailalogo,
    MenuList: [
      {
        id: 1,
        title: "Products",
        subItems: [
          {
            title: "Our Products List",
            subItems: [
              {
                title: "Tymeplus",
                subTitle: "HR management Tool",
                logo: "",
                url: "/tymeplus",
              },
              {
                title: "TracTask",
                subTitle: "Task Management and Project Management ",
                logo: "",
                url: "/tractask",
              },
              {
                title: "FieldSale",
                subTitle: "Sales Management",
                logo: "",
                url: "/fieldsale",
              },
              {
                title: "CRM",
                subTitle: "Marketing Tool",
                logo: "",
                url: "/crm",
              },
            ],
          },
        ],
        subNavigators: [],
      },
      {
        id: 2,
        title: "Resources",
        subItems: [],
        url: '/calculator',
        // component: <Calculator />,
      },
    ],
    ActionButtonList: [
      {
        name: "Contact",
        type: "filled",
        url: "/register"
      },
      {
        name: "Create Insident",
        type: "filled",
        url: "/add-insident"
      }
      
    ],
  },
  help:"How can we help?",
  banner:
    "Find answers via BDO support resources",

  introduction: {
    ProductsIcons: [
      { icon: tymeplusLogo, url: "/tymeplus" },
      { icon: tracktaskLogo, url: "/tractask" },
      { icon: fieldsaleLogo, url: "/fieldsale" },
      { icon: crmLogo, url: "/crm"}
    ],
    Details: {
      heading:
        "In today's Marketplace, teams of all sizes utilize diverse tools to connect, create, and collaborate seamlessly.",
      summary: "A flexible, innovative solution for people and organisations to achieve more.",
      buttonName: "Register account",
    },
    image: introImage,
    Video: videoContent,
    VideoHeading: "Productivity and collaboration tools for all the ways that we work.",
  },

  Card: {
    tag: "ALL THE LATEST",
    title: "Learn more about the future of work.",
    summary: "Stay up to date with the latest stories, events and more with Google Workspace.",
    cards: [
      {
        image: tymeplusDashboard,
        title: "Unlock new ways of working with Duet AI in Workspace.",
        tag: "ANNOUNCEMENT",
        ButtonName: "Learn more",
      },
      {
        image: tractaskdark,
        title: "Discover new insights and stories about the future of work.",
        tag: "TRANSFORMATION",
        ButtonName: "Learn more",
      },
      {
        image: filedsaleDashboard,
        title:
          "Stay up to date with the latest stories and product news on the Google Workspace blog.",
        tag: "News",
        ButtonName: "Learn more",
      },
      {
        image: tractaskwhite,
        title: "5 Reasons Google Workspace Enables Anytime, Anywhere Collaboration",
        tag: "guide",
        ButtonName: "Learn more",
      },
      {
        image: crmdashboard,
        title: "Champions of Change - Celebrating the heroes behind workplace transformation",
        tag: "Videos",
        ButtonName: "Learn more",
      },
    ],
  },

  questions: {
    title: "Find the answers that you need",
    list: [
      {
        id: 1,
        question: "What's included with waila marketplace ?",
        answer:
          "Similar to the W Suite, all Waila Marketplace plans provide a custom email for your business and include collaboration tools such as Tymeplus, TrackTask, FieldSale, and more. For additional details, please visit our plans and pricing page.",
      },
      {
        id: 2,
        question: "What's included with waila marketplace ?",
        answer:
          "Similar to the W Suite, all Waila Marketplace plans provide a custom email for your business and include collaboration tools such as Tymeplus, TrackTask, FieldSale, and more. For additional details, please visit our plans and pricing page.",
      },
      {
        id: 3,
        question: "What's included with waila marketplace ?",
        answer:
          "Similar to the W Suite, all Waila Marketplace plans provide a custom email for your business and include collaboration tools such as Tymeplus, TrackTask, FieldSale, and more. For additional details, please visit our plans and pricing page.",
      },
    ],
    containerList: [
      {
        id: 1,
        title: "Explore Learning Centre",
        summary: "Browse tips, guides and how-to videos made for both new users and power users.",
        buttonName: "See blogs",
        url: "https://www.blog.waila.co/",
        clickEventName: "SeeResources",
      },
      {
        id: 2,
        title: "Get help",
        summary: "Sign in to find specific answers, manage users or get support as a developer.",
        buttonName: "Get support",
        url: "https://www.support.waila.co/",
        clickEventName: "GetSupport",
      },
    ],
  },

  trailInfo: {
    title: "Transform the way you work.",
    buttonName: "Register account",
  },

  footer: {
    socialMediaQoutes: "Follow our Blog",
    socialMediaIcons: [
      { icon: tymeplusLogo, url: "/tymeplus" },
      { icon: tracktaskLogo, url: "/tractask" },
      { icon: fieldsaleLogo, url: "/fieldsale" },
      { icon: crmLogo, url: "/crm"}
    ],
    listSites: [
      {
        title: "Web Applications",
        subItems: [
          {
            name: "Tymeplus",
            url: "/tymeplus"
          },
          {
            name: "TracTask",
            url: "/tractask"
          },
          {
            name: "FieldSale",
            url: "/fieldsale"
          },
          {
            name: "CRM",
            url: "/crm"
          }
        ],
      },
      // {
      //   title: "Security and management",
      //   subItems: [
      //     {
      //       name: "Admin",
      //     },
      //     {
      //       name: "Endpoint",
      //     },
      //     {
      //       name: "Vault",
      //     },
      //     {
      //       name: "Work Insights",
      //     },
      //   ],
      // },
      {
        title: "Support Center",
        subItems: [
          {
            name: "Blog",
            url: "https://www.blog.waila.co/"
          },
          {
            name: "Support",
            url: "https://www.support.waila.co/"
          },
        ],
      },
      // {
      //   title: "Solutions",
      //   subItems: [
      //     {
      //       name: "New business",
      //     },
      //     {
      //       name: "Small business",
      //     },
      //     {
      //       name: "Enterprise",
      //     },
      //     {
      //       name: "For Investors",
      //       url: "/investor",
      //     },
      //   ],
      // },
      {
        title: "Pricing",
        subItems: [
          {
            name: "Calculator",
            url: '/calculator'
          },
        ],
      },
      {
        title: "Social Media",
        subItems: [
          {
            name: "Facebook",
            url: "https://www.facebook.com/profile.php?id=61550634920838",
          },
          {
            name: "Youtube",
            url: "",
          },
          {
            name: "Instagram",
            url: "https://www.linkedin.com/company/waila-technology/posts/?feedView=all",
          },
          {
            name: "Twitter",
            url: "https://twitter.com/wailatech",
          },
          {
            name: "LinkedIn",
            url: "https://www.linkedin.com/company/waila-technology/posts/?feedView=all",
          },
        ],
      },
      {
        title: "App Link",
        subItems: [
          {
            name: "Tymeplus App",
            url: '/tymeplus'
          },
          {
            name: "TracTask App",
            url: '/tractask'
          },
          {
            name: "Field sale App",
            url: '/fieldsale'
          },
          {
            name: "CRM App",
            url: '/crm'
          },
        ],
      },
    ],

    siteTitle: "Waila Marketplace | W-Asia",
    privacy : {
      name:"Privacy and Policy",
      url:"/privacy"
    },
    country  : [
      "Australia",
      "Asia",
      "India",
      "Europe",
      "Africa",
      "America"
    ]
  },
}
